@import '../../../../scss/theme-bootstrap';

.basic-formatter-concerns {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  position: relative;
  &__header {
    margin-top: 30px;
    @include breakpoint($landscape-up) {
      margin-top: 220px;
    }
  }
  &.content-container {
    padding: 0;
    @include breakpoint($landscape-up) {
      padding: 0 60px;
    }
  }
  &__nav {
    position: relative;
    margin-top: 20px;
    @include breakpoint($landscape-up) {
      margin-top: 0;
    }
  }
  &__nav-title {
    position: absolute;
    width: 100%;
    top: -10px;
    color: $color-light-gray;
    @include breakpoint($landscape-up) {
      display: none;
    }
    span {
      background: $white;
      padding: 0 5px;
    }
  }
  &__tab-links {
    display: none;
    @include breakpoint($landscape-up) {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid $color-lighter-gray;
      margin-top: 15px;
      & li {
        cursor: pointer;
        margin: 0 30px 0 0;
        padding: 0 0 10px 0;
        font-family: $main-font-medium;
        font-size: 13px;
        position: relative;
        white-space: nowrap;
        letter-spacing: 2px;
        &.active {
          font-weight: bold;
          border-width: 3px;
          &:after {
            content: '\00a0';
            position: absolute;
            height: 3px;
            bottom: 0;
            left: 0;
            width: 100%;
            background: $color-lighter-green; /* Old browsers */
            background: -moz-linear-gradient(
              left,
              $color-lighter-green 2%,
              $color-darker-green 30%,
              $color-darker-green 30%,
              $color-light-green 59%,
              $color-lighter-green 100%
            ); /* FF3.6-15 */
            background: -webkit-linear-gradient(
              left,
              $color-lighter-green 2%,
              $color-darker-green 30%,
              $color-darker-green 30%,
              $color-light-green 59%,
              $color-lighter-green 100%
            ); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
              to right,
              $color-lighter-green 2%,
              $color-darker-green 30%,
              $color-darker-green 30%,
              $color-light-green 59%,
              $color-lighter-green 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-lighter-green', endColorstr='$color-lighter-green',GradientType=1 ); /* IE6-9 */
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__tab-select {
    margin: 0 auto;
    width: 90%;
    display: block;
    text-align: center;
    height: auto;
    // font-family: $main-font-medium;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
}

.concern-block {
  display: flex;
  align-items: stretch;
  padding: 20px 0;
  &__products {
    width: 100%;
    @include breakpoint($landscape-up) {
      width: 70%;
      margin-right: 20px;
    }
    @include breakpoint($max-width) {
      width: 70%;
      margin-right: 0;
    }
    & .product-grid--carousel {
      .slick-prev {
        left: 25px;
      }
      .slick-next {
        right: 30px;
      }
    }
  }
}

.solution-block {
  display: none;
  @include breakpoint($landscape-up) {
    display: block;
    width: 30%;
    margin-left: 20px;
  }
  @include breakpoint($max-width) {
    width: 25%;
    margin-left: 0;
    max-height: 592px;
  }
  background: $color-light-green-transparent;
  &__text {
    padding: 40px 20px;
    @include breakpoint($landscape-up) {
      padding: 30px;
    }
    ul {
      margin: 35px 0;
    }
    li {
      padding-bottom: 28px;
      &:before {
        content: '\2022';
        color: $white;
        margin-right: 10px;
        font-size: 30px;
        line-height: 0;
        margin-top: 10px;
        margin-bottom: 40px;
        display: block;
        float: left;
      }
    }
  }
}
